export default {
  siteTitle: 'Get bonus',
  blogerName: 'NORDESTINO',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://youtube.com/@NordestinodaForra'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@NordestinodeNegocios'
    },
    {
      name: 'instagram',
      url: 'https://instagram.com/amorcomfeasmr'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+5axErMIJYydmM2Zh'
    }
  ],
  projects: [
    {
      name: 'drip',
      url: 'https://dripcasino.life/ccdacfdbb',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c63728c0e',
      gameTitle: 'Izzi Art (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c806bba92',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c2f850e0a',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/ccd62cc0a',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c90c97263',
      gameTitle: 'Starda Queen (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/ce5939c32',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>NORDESTINO</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'NORDESTINO',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
